import React from 'react'

export const JapanesePolicy = () => {
  return (
    <>
      <h2>広告ついて</h2>
      <div>
        当サイトでは、第三者配信の広告サービス（Googleアドセンス、Amazonアソシエイト）を利用しています。
        広告配信事業者は、ユーザーの興味に応じた広告を表示するためにCookie（クッキー）を使用することがあります。
        Cookie（クッキー）を無効にする設定およびGoogleアドセンスに関する詳細は
        <a
          href={'https://www.google.co.jp/policies/technologies/ads/'}
          target={'_blank'}
          rel={'nofollow'}
        >
          「広告 – ポリシーと規約 – Google」
        </a>
        をご覧ください。
        また、当サイトは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。
      </div>

      <h2>アクセス解析ツールについて</h2>
      <div>
        当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
        このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは
        <a
          href={'https://www.google.com/analytics/terms/jp.html'}
          target={'_blank'}
          rel={'nofollow'}
        >
          こちら
        </a>
        をクリックしてください。
      </div>

      <h2>免責事項</h2>
      <div>
        当サイトで掲載している画像の著作権・肖像権等は各権利所有者に帰属致します。権利を侵害する目的ではございません。記事の内容や掲載画像等に問題がございましたら、各権利所有者様本人が直接メールでご連絡下さい。確認後、対応させて頂きます。
        当サイトからリンクやバナーなどによって他のサイトに移動された場合、移動先サイトで提供される情報、サービス等について一切の責任を負いません。
        当サイトのコンテンツ・情報につきまして、可能な限り正確な情報を掲載するよう努めておりますが、誤情報が入り込んだり、情報が古くなっていることもございます。
        当サイトに掲載された内容によって生じた損害等の一切の責任を負いかねますのでご了承ください。
      </div>
    </>
  )
}
