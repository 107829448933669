import React from 'react'

export const EnglishPolicy = () => {
  return (
    <>
      <h2>Advertising Partners</h2>
      <div>
        Some of advertisers on this website may use cookies and web beacons. Advertising partners
        are listed below. Each of our advertising partners has their own Privacy Policy for their
        policies on user data. For easier access, I hyperlinked to their Privacy Policies below.
        <ul>
          <li>
            {'・'}
            <a
              href={'https://www.google.co.jp/policies/technologies/ads/'}
              target={'_blank'}
              rel={'nofollow'}
            >
              Google
            </a>
          </li>
          <li>
            {'・'}
            <a href={'https://affiliate-program.amazon.com/'} target={'_blank'} rel={'nofollow'}>
              Amazon
            </a>
          </li>
        </ul>
      </div>

      <h2>Log Data</h2>
      <div>
        When you visit this website, third party's servers may automatically log the standard data
        provided by your web browser. It may include your device’s Internet Protocol (IP) address,
        your browser type and version, the pages you visit, the time and date of your visit, the
        time spent on each page, other details about your visit, and technical details that occur in
        conjunction with any errors you may encounter. Please be aware that while this information
        may not be personally identifying by itself, it may be possible to combine it with other
        data to personally identify individual persons.
        <ul>
          <li>
            {'・'}
            <a
              href={'https://www.google.com/analytics/terms/jp.html'}
              target={'_blank'}
              rel={'nofollow'}
            >
              Google Analytics
            </a>
          </li>
        </ul>
      </div>

      <h2>Use of Cookies</h2>
      <div>
        This site use “cookies” to collect information about you and your activity across our site.
        A cookie is a small piece of data that our website stores on your computer, and accesses
        each time you visit, so we can understand how you use our site. This helps us serve you
        content based on preferences you have specified.
      </div>

      <h2>Limits of Policy</h2>
      <div>
        This site may link to external sites that are not operated by me. Please be aware that I
        have no control over the content and policies of those sites, and cannot accept
        responsibility or liability for their respective privacy practices.
      </div>
    </>
  )
}
