/** 1. Imports **/
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { useI18next } from 'gatsby-plugin-react-i18next'

import { Layout } from '../components/layout/layout'
import { MetaTag } from '../components/layout/metaTag'
import { EnglishPolicy } from '../components/privacyPolicy/englishPolicy'
import { JapanesePolicy } from '../components/privacyPolicy/japanesePolicy'

/** 2. Types **/
type Props = {
  className?: string
}

type ComponentProps = {
  className?: string
  content: any
}

/** 3. Base component **/
const Component = ({ className, content }: ComponentProps) => (
  <Layout>
    <MetaTag title={'Privacy Policy'} />
    <div className={className}>{content}</div>
  </Layout>
)

/** 4. Styled component **/
const StyledComponent = styled(Component)`
  max-width: 560px;
  margin: 0 auto;

  > h2 {
    margin-bottom: 16px;
    font-size: 2rem;
    font-weight: 700;
  }

  > div {
    margin-bottom: 80px;
    font-size: 1.6rem;
    line-height: 2;
    color: ${({ theme }) => theme.color.subTextGrey};

    ul {
      margin: 24px 0;
    }

    a {
      color: ${({ theme }) => theme.color.highlightMagenta};
    }
  }
`

/** 5. Container **/
const PrivacyPolicy = (props: Props) => {
  const { language } = useI18next()

  return (
    <StyledComponent
      {...props}
      content={language === 'en' ? <EnglishPolicy /> : <JapanesePolicy />}
    />
  )
}
export default PrivacyPolicy

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
